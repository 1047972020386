import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => registration.unregister());
  });
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);