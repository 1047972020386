import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { deepmerge } from "@mui/utils"; // Needed to merge custom colors

const augmentColor = (color) => ({
  main: color,
});

let Theme = createTheme({
  palette: {
    primary: {
      main: "#C2185B",
    },
    iconPrimary: augmentColor("#ffffff"), 
  },
  status: {
    new: "#9B0000",
    designing: "#C8B900",
    ready: {
      pickup: "#00ACC1",
      delivery: "#0C616B",
    },
    delivering: "#78909C",
    complete: "#388E3C",
  },
});

Theme = responsiveFontSizes(Theme);

export default Theme;
