import { io } from "socket.io-client";
import axios from "axios";

const API_ENV = process.env.REACT_APP_API_ENV || "local";
const hostSite =
  API_ENV === "live"
    ? process.env.REACT_APP_API_LIVE || "https://florus-la-catrina.nn.r.appspot.com"
    : process.env.REACT_APP_API_LOCAL || "http://localhost:8080";

const socket = new io(hostSite);
const API = axios.create({
  baseURL: hostSite,
  withCredentials: true,
});

let DB = {
  login: async (username, password) => {
    let data =
      username && password ? { username: username, password: password } : null;
    return API.post(`/users/login`, data)
      .then((res) => res.data)
      .catch((e) => console.warn(e));
  },

  logout: async (userId) => {
    return API.post(`/users/logout`, { userId: userId }).then(
      (res) => res.data
    );
  },

  submitOrder: async (data, callback) => {
    data.orderDate = data.order_date.toDate();
    data.timeFrame = data.time_frame;
    data.isDelivery = data.order_type;
    data.clientNum = data.client_number;
    data.rNum = data.recipient_number;

    delete data.order_date;
    delete data.time_frame;
    delete data.order_type;
    delete data.client_number;
    delete data.recipient_number;

    data.details = `${data.details.join(" ")} ${
      data.extra_details ? `\n ${data.extra_details}` : ""
    }`;

    delete data.extra_details;

    if (data.delivery_fee !== 0)
      data.details += `\n$${data.delivery_fee} Delivery Fee`;

    delete data.delivery_fee;

    if (data.payment && data.payment === "Paid in store")
      data.payment = { type: "in-store" };
    else if (data.payment)
      data.payment = { type: "card", ...JSON.parse(data.payment) };

    return API.post(`/orders`, data).then((res) => {
      callback(res.data);
    });
  },

  getUsers: async (callback) => {
    return API.get(`/users`).then((res) => {
      callback(res.data);
    });
  },

  updateUser: async (data, callback) => {
    return API.post(`/users/user/update`, data).then((res) => {
      callback(res.data);
    });
  },

  updatePassword: async (userId, oldPword, newPword, callback) => {
    let data = {
      userId: userId,
      oldPassword: oldPword,
      newPassword: newPword,
    };

    return API.post(`/users/user/update/password`, data).then((res) => {
      callback(res.data);
    });
  },

  getOrders: async ({
    date = null,
    status = null,
    user = null,
    sort = null,
    search = "",
    limit = null,
    offset = null,
    callback = null,
    orderType = null,
  } = {}) => {
    let data = {
      date: date,
      status: status,
      user: user,
      sort: sort,
      search: search,
      limit: limit,
      offset: offset,
      orderType: orderType,
    };

    try {
      data = JSON.stringify(data);
      return API.get(`/orders/data/${data}`).then((res) => {
        let [amount, orders] = res.data;
        if (callback) callback(orders, amount);
        return [orders, amount];
      });
    } catch (e) {
      console.warn(e);
    }
  },

  updateOrder: async (data) => {
    return API.post(`/orders/update`, data).then((res) => res.data);
  },

  deleteOrder: async (orderId) => {
    return API.post(`/orders/delete`, {orderId: orderId}).then((res) => res.data);
  },

  listen: async (event, func) => {
    socket.on(event, func);
  },

  stopListening: async (event, func) => {
    socket.off(event, func);
  },

  initializePayment: async (data, callback) => {
    socket.emit("squarePayment", data, (res) => {
      callback(res);
    });
  },

  payOrder: async (data, callback) => {
    socket.emit("payOrder", data, (res) => {
      callback(res);
    });
  },

  getSysData: async (data, callback) => {
    let sendData = {};
    for (const dataName of data) sendData[dataName] = true;

    try {
      sendData = JSON.stringify(sendData);
      return API.get(`/system/${sendData}`).then((res) => {
        callback(res.data);
      });
    } catch (e) {
      console.warn(e);
    }
  },

  updateStoreIp: async (newVal, callback) => {
    return API.post(`/update/permission`, { id: 4, comparison: newVal }).then(
      (res) => {
        callback(res.data);
      }
    );
  },

  setPermission: (userId, permissionId, value, callback) => {
    socket.emit(
      "updateUserPermission",
      { permissionId: permissionId, val: value, user: userId },
      (res) => {
        callback(res);
      }
    );
  },

  hasPermission: async (
    permission,
    user,
    { comparison = null, callback = null }
  ) => {
    let data = {
      permission: permission,
      user: user,
      comparison: comparison,
    };

    let results = await new Promise((resolve) =>
      socket.emit("checkPermission", data, (res) => {
        resolve(res);
      })
    );

    if (callback) callback(results);

    return results;
  },
};

export default DB;
