import React, { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserContext, UsersContext } from "./Contexts/UserContext";
import { DB, Theme } from "./utils";

import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Snackbar from "@mui/material/Snackbar";

import CloseIcon from "@mui/icons-material/Close";

const Layout = lazy(() => import("./Pages/Layout"));
const Home = lazy(() => import("./Pages/Home"));
const Login = lazy(() => import("./Pages/Login"));
const Orders = lazy(() => import("./Pages/Orders"));
const Search = lazy(() => import("./Pages/Search"));
const Settings = lazy(() => import("./Pages/Settings"));
const Error = lazy(() => import("./Pages/Error"));

const App = () => {
  const [loading, setLoading] = useState(true);
  const [acceptedCookie, setAcceptedCookie] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({
    userId: -1,
    name: "Not Available",
    username: "N/A",
    firstName: "N/A",
    initials: "N/A",
    id: "N/A",
    permissions: [],
  });
  const [users, setUsers] = useState(
    new Map([
      [
        -1,
        {
          name: "Not Available",
          firstName: "N/A",
          initials: "N/A",
          id: "N/A",
          permissions: [],
        },
      ],
    ])
  );

  const login = async (username, password) => {
    return DB.login(username, password)
      .then(({ success, userId, user }) => {
        if (success) {
          var nameInfo = user.name.split(" ");
          var initials = [];

          for (var name of nameInfo)
            initials.push(name.charAt(0).toUpperCase());

          setUser({
            userId: userId,
            name: user.name,
            firstName: nameInfo[0],
            initials: initials.join(""),
            username: user.username,
            permissions: user.permissions,
          });
          setLoggedIn(true);
        }

        setLoading(false);

        return success;
      })
      .catch((e) => {
        setLoading(false);
        return console.warn(e);
      });
  };

  const logout = async () => {
    let userId = user.userId;
    return DB.logout(userId).then((success) => {
      if (success) {
        setUser({ userId: -1 });
        setLoggedIn(false);
      }
    });
  };

  const updateUser = (id, data) => {
    let updateUser = users.get(id);

    if (!updateUser) return;

    let newUsers = new Map(users);
    updateUser = { ...newUsers.get(id), ...data };
    newUsers.set(id, updateUser);
    setUsers(newUsers);

    if (updateUser.id === user.id) setUser({ ...user, ...updateUser });
  };

  const updateUsers = (newUsers) => {
    setUsers(newUsers);
  };

  const checkCookies = async (module) => {
    let mod = await module;
    let Cookies = mod.default;
    let cookies = new Cookies();

    if (!cookies.get("accepted-cookies")) setAcceptedCookie(false);
  };

  const acceptCookie = () => {
    let ranStr = "";
    let ranLength = Math.floor(Math.random() * 15) + 1;
    for (var i = 1; i <= ranLength; i++) ranStr += Math.random() * 100;

    import("universal-cookie").then((mod) => {
      let Cookies = mod.default;
      let cookies = new Cookies();
      cookies.set("accepted-cookies", ranStr, {
        secure: true,
        sameSite: "strict",
        expires: new Date(2099, 1, 15),
      });
      setAcceptedCookie(true);
    });
  };

  useEffect(() => {
    login();

    let Cookies = import("universal-cookie");
    checkCookies(Cookies);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      DB.getUsers((rows) => {
        let newUsers = new Map();

        for (var row of rows) {
          var nameInfo = row.name.split(" ");
          var initials = [];

          for (var name of nameInfo)
            initials.push(name.charAt(0).toUpperCase());

          let userInfo = {
            name: row.name,
            firstName: nameInfo[0],
            initials: initials.join(""),
            id: row.userId,
            permissions: row.permissions,
          };

          newUsers.set(row.userId, userInfo);
        }

        try {
          setUsers(newUsers);
        } catch (e) {
          console.warn(e);
        }
      });
    }
  }, [loggedIn, user]);

  return (
    <React.Fragment>
      {loading ? (
        <div></div>
      ) : (
        <ThemeProvider theme={Theme}>
          <UsersContext.Provider value={users}>
            <UserContext.Provider value={user}>
              <div className="App">
                <Suspense
                  fallback={
                    <Backdrop sx={{ color: "#fff", zIndex: 100 }} open={true}>
                      <CircularProgress />
                    </Backdrop>
                  }
                >
                  {!loggedIn ? (
                    <Login login={login} />
                  ) : (
                    <BrowserRouter>
                      <Routes>
                        <Route path="/" element={<Layout logout={logout} />}>
                          <Route path="/" element={<Home />} />
                          <Route path="home" element={<Home />} />
                          <Route path="orders" element={<Orders type="all" />} />
                          <Route path="search" element={<Search />} />
                          <Route path="search/:search" element={<Search />} />
                          <Route
                            path="settings"
                            element={
                              <Settings
                                updateUser={updateUser}
                                updateUsers={updateUsers}
                              />
                            }
                          />
                          <Route path="*" element={<Error />} />
                        </Route>
                      </Routes>
                    </BrowserRouter>
                  )}
                </Suspense>
                <Snackbar
                  open={!acceptedCookie}
                  message="By using this website, you agree to our use of cookies. Cookies are small text files that are stored on your device. We use cookies to enhance your experience while using this web app. If you do not agree to the use of cookies, please do not use this website. Thank you!"
                  sx={{ maxWidth: 300 }}
                  action={
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={acceptCookie}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                />
              </div>
            </UserContext.Provider>
          </UsersContext.Provider>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default App;
